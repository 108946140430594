var __jsx = React.createElement;
import React, { useState, Component, Fragment } from 'react';
import Anchor from '../../../atoms/Anchor';
import analyticsConstants from '../../../../constants/analyticsConstants';
var headerAnalytics = analyticsConstants.globalHeader;
var Logo = function Logo(_ref) {
  var headerLogo = _ref.headerLogo;
  return __jsx("div", {
    className: "nva-header__logo"
  }, __jsx(Anchor, {
    title: "Logo",
    to: "/",
    "data-analytics-type": headerAnalytics.analyticsType.GLOBAL_NAV,
    "data-analytics-value": headerAnalytics.analyticsValue.GLOBAL_NAV_LOGO
  }, __jsx("img", {
    src: "".concat(headerLogo.url, "?fm=webp").concat(headerLogo.width > 400 ? '&w=400' : ''),
    alt: headerLogo.title || 'Logo',
    title: headerLogo.title || 'Logo',
    decoding: "async",
    fetchpriority: "high"
  })));
};
export default Logo;