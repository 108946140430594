var __jsx = React.createElement;
import React from 'react';
import NavMenu from '../../../molecules/NavList';
var CTAMenu = function CTAMenu(_ref) {
  var ctaMenuCollection = _ref.ctaMenuCollection,
    openDropdownOnClick = _ref.openDropdownOnClick;
  var ctaMenu = ctaMenuCollection.items;
  return __jsx("div", {
    className: "nva-footer__cta-menu"
  }, __jsx(NavMenu, {
    className: "nva-footer__cta-menu-list",
    menu: ctaMenu,
    menuType: "cta",
    openDropdownOnClick: openDropdownOnClick
  }));
};
export default CTAMenu;